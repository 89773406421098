import {useCallback, useEffect, useRef, useState} from 'react';
import { useLocation } from 'react-router-dom';
import axiosBoard from '@/services/api/apis/api-board';
import { blogBoard as Styled } from '@/styles';
import BoardList from '@/components/blog/list/board-list';
import Pagination from '@/components/blog/list/pagination.tsx';

const Board = () => {
  const location = useLocation();
  const [list, setList] = useState([]);
  const [mainCategory, setMainCategory] = useState('');
  const [tag, setTag] = useState('');
  const [totalPage, setTotalPage] = useState(0);
  const wrapBoardList = useRef<HTMLDivElement | null>(null);
  const countList = useRef(0);
  const page = useRef(0);

  const getPageList = useCallback(() => {
    const formData = new FormData();
    formData.append('tag', location.state?.tag);
    formData.append('page', String(page.current));
    formData.append('countList', String(countList.current));

    axiosBoard
      .boardList(formData)
      .then((resultBoard) => {
        setList(resultBoard.data?.data.list);
        setTotalPage(Math.ceil(resultBoard.data.data.totalCount / countList.current));
        console.log(resultBoard.data?.data.list)
      })
      .catch((error: Error) => {
        console.log(error.message);
      });
  }, [location.state?.tag]);

  useEffect(() => {
    const mainCategoryName = location.state?.mainCategory;
    setMainCategory(mainCategoryName);
  }, [location.state.mainCategory, location.state.subCategory]);

  useEffect(() => {
    countList.current = Math.round(((window.innerHeight - 362) - 100) / 70);
    const tagName = location.state?.tag;
    setTag(tagName);

    getPageList();

  }, [getPageList, location.state?.tag]);


  const handlePageChange = (pageNumber: number) => {
    console.log(pageNumber - 1);
    page.current = pageNumber - 1;
    // 여기서 해당 페이지에 대한 데이터를 로드하는 로직을 추가할 수 있습니다.

    getPageList();
  };

  return (
    <>
      <Styled.MainComponent>
        <Styled.TopHeader>
          <Styled.SubText>{mainCategory}</Styled.SubText>
          <Styled.MainText>:: {tag} ::</Styled.MainText>
        </Styled.TopHeader>
      </Styled.MainComponent>

      <div className="wrap-board-list" ref={wrapBoardList}>
        <BoardList listData={list} board={tag} />
      </div>

      <Pagination
        totalPages={totalPage}
        currentPage={page.current}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default Board;
