
interface Props {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination = (props: Props) => {
  const pageNumbers = [];

  if (props.totalPages > 1) {
    for (let i = 1; i <= props.totalPages; i++) {
      pageNumbers.push(i);
    }

    console.log(pageNumbers)
  }


  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((page) => (
          <li key={page} style={{ margin: '0 5px' }}>
            <button
              onClick={() => props.onPageChange(page)}
              className={ props.currentPage + 1 === page ? 'active' : undefined }
            >
              {page}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
